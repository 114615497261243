/*
* @Author: Jeff Shields
* @Date:   2019-03-27 10:49:09
* @Last Modified by:   Jeff Shields
* @Last Modified time: 2020-01-25 11:48:16
*/

//
// nav bar settings for mobi style header
//
@if $use-mobi {
    .nav-logo {
        height: 2.6em;
        margin-left: 1em;

        @include media-breakpoint-up(md) {
            height: 3em;
            margin-left: 3em;
        }

        @include media-breakpoint-up(lg) {
            height: 3.5em;
            margin-left: 4em;
        }

    }

    .mbr-section-btn a.btn:not(.btn-form) {
        border-radius: 100px;
    }

    .mobi .btn {
        padding: 0.4rem 1.5rem;
        display: -webkit-inline-flex;
        align-items: center;
        -webkit-align-items: center;
    }

    .btn-white-outline {
        background: none;
        border-color: #ffffff;
        color: $navbar-dark-color;
    }

    .btn-white-outline:active,
    .btn-white-outline.active {
        background: #fff;
        border-color: #ffffff;
        color: #000;
    }

}
