/*! extras
* @Author: Jeff Shields
* @Date:   2019-01-20 07:34:24
* @Last Modified by:   Jeff Shields
* @Last Modified time: 2020-01-25 07:11:31
*/



// common to all themes
@import "project/variables";

@import "mythemes/default/variables";

// load the bootstrap functions, bs4 variables & mixins,
@import "project/bs4-required";

// load the extras
@import "extras/index";
