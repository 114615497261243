/*
* @Author: Jeff Shields
* @Date:   2019-03-31 07:42:46
* @Last Modified by:   Jeff Shields
* @Last Modified time: 2020-01-25 11:41:35
*/

// ==========================================================================
// for realty ninja idx feeds
// ==========================================================================

@if $enable-idx-feed {
    .loading-rn {
        display: none;
    }


    #embeded iframe,
    .embeded iframe {
        width: 100%;
    }
}
