/*
    Custom styles

*/

// increase the font sixe for large screens
@include media-breakpoint-up(xl) {
    html {
        font-size: $html-fontsize-xl;
    }
    .navbar * {
        font-size: $navbar-fontsize-xl;
    }
}


// fix for navbar long dropdown-menu
// set the height to 70% for the screen and add scrollbars
// .fixed-top .dropdown-menu {
//   max-height: 50vh;
//   overflow-y: auto;
// }

// limit width incase of extra long brand images
.navbar-brand {
    max-width: 75%;
}

// for vertical rhythum consistance
 .rhythum {
     margin-bottom: 1.5rem !important;
 }

.picture-frame {
    border: 5px ridge $warning;
}

// fix for addthis floating side shares
.content,
.footer {
    @include media-breakpoint-up(lg) {
        padding-left: 3rem;
        padding-right: 3rem;
    }
}

// images
// img-info[1-5]
.img-info1{
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.img-info1:before,
.img-info:after {
    content: '';
}
