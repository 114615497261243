/*
* @Author: Jeff Shields
* @Date:   2020-01-25 06:55:12
* @Last Modified by:   Jeff Shields
* @Last Modified time: 2020-01-25 11:50:48
*/
$use-ribbon: true !default;
$use-mobi: true !default;
$use-audio: false !default;
$use-back-to-top: true !default;
$enable-bootnavbar: true !default;
$enable-idx-feed: true !default;
$enable-starburst: false !default;

// for google maps display
$google-maps-width: 98% !default;
$google-maps-height: 20rem !default;
$google-maps-height--large: 25rem !default;

// number of cards per line for each breakpoint
$cards-per-line: (
    xs: 1,
    sm: 2,
    md: 2,
    lg: 3,
    xl: 4
) !default;
