//  islands are used to float content
//  adds margin to content side
//  defaults to 33% wide
//  sm and lg modiifiers
//  requires a d-block wrapper as float is ignored in flex

.island, .island-left{
    max-width: 100%;
    margin-left: auto ;
    margin-right: auto ;
}

@include media-breakpoint-up(sm) {
    .island{
        max-width: 33%;
        margin-left: 1em ;
        float:right;
        }

    .island-left{
        max-width: 33%;
        margin-right: 1em ;
        float:left;
    }

    .island-sm {
        max-width: 25%;
    }

    .island-lg {
        max-width: 50%;
    }
}
