/*
* @Author: Jeff Shields
* @Date:   2019-03-31 07:40:09
* @Last Modified by:   Jeff Shields
* @Last Modified time: 2020-05-29 12:08:55
*/

.overlay {
    background-color: #000;
    opacity: 1;
    // pointer-events: none;
}

// .overlay {
//     display: block !important;
//     opacity: 0;
// }

.overlay:hover {
    background-color: #efefef;
    opacity: .5;
    transition: opacity .3s;
}

.image-container {
    position: relative;
    background-color: #000;
}

.image-overlay {
    position: absolute;
    left: 0;
    top: 2px;
    opacity: 1;
    background-repeat: no-repeat;
    width: 100%;
    height: 100%;
    z-index: 1029;
}
